import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Badge,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Popover,
  Divider,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUIState } from "../../context/uiState";
import { useUser } from "../../context/user";
import { NotificationsOutlined as NotificationsIcon } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDistanceToNow } from "date-fns";
import APIService from "../../services/APIService";
import { NotificationState } from "../../context/user/context";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@emotion/react";
import { debounce } from "lodash";

// Replace the pulseAnimation with fadeInAnimation
const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const notificationAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.2) rotate(-3deg);
  }
  70% {
    transform: scale(1.2) rotate(3deg);
  }
  80% {
    transform: scale(1.2) rotate(-3deg);
  }
  90% {
    transform: scale(1.2) rotate(3deg);
  }
  100% {
    transform: scale(0.5);
  }
`;

export const ShowNotifications: React.FC = () => {
  const navigate = useNavigate();
  const { showAlertBanner } = useUIState();
  const {
    userState,
    getAccessToken,
    setNotifications: setUserNotifications,
  } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<NotificationState[]>([]);
  const [prevNotifications, setPrevNotifications] = useState<
    NotificationState[]
  >([]);

  const { notifications: sortedNotifications, unopenedNotifications } =
    useMemo(() => {
      if (userState?.notifications) {
        const sortedNotifications = [...userState.notifications].sort(
          (a, b) => (b.createdAt || 0) - (a.createdAt || 0)
        );
        const unopened = sortedNotifications.filter(
          (n) => n.isOpened === 0
        ).length;

        if (
          JSON.stringify(sortedNotifications) !==
          JSON.stringify(prevNotifications)
        ) {
          setPrevNotifications(sortedNotifications);
        }

        return {
          notifications: sortedNotifications,
          unopenedNotifications: unopened,
        };
      }
      return { notifications: [], unopenedNotifications: 0 };
    }, [userState?.notifications, prevNotifications]);

  useEffect(() => {
    if (
      localStorage.getItem("sharedTemplateAdded") &&
      localStorage.getItem("sharedTemplateAdded") != ""
    ) {
      showAlertBanner("A new template has been added", "success", true, () => {
        navigate("/templates");
      });
      localStorage.removeItem("sharedTemplateAdded");
    }
  }, [localStorage.getItem("sharedTemplateAdded")]);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const handleNotificationClick = async (notification: NotificationState) => {
    try {
      const accessToken = await getAccessToken();
      await APIService.makeAPIPostRequest({
        requestString: "/user/notificationViewed",
        accessToken,
        body: {
          notificationId: notification.notification_id,
        },
      });
    } catch (error) {
      console.error("Failed to mark notification as opened", error);
    } finally {
      if (notification.external_url) {
        window.location.href = notification.external_url;
      } else if (notification.internal_url) {
        navigate(notification.internal_url);
      }
    }
  };

  const handleRemoveNotification = async (notification: NotificationState) => {
    try {
      const accessToken = await getAccessToken();
      await APIService.makeAPIPostRequest({
        requestString: "/user/removeNotification",
        accessToken,
        body: {
          notificationId: notification.notification_id,
        },
      });

      // Update the notifications in the user context
      if (userState && userState.notifications) {
        const updatedNotifications = userState.notifications.filter(
          (n) => n.notification_id !== notification.notification_id
        );
        setUserNotifications(updatedNotifications);
      }
    } catch (error) {
      console.error("Failed to remove notification", error);
    }
  };

  const handleNotificationHover = useMemo(
    () =>
      debounce(async (notification: NotificationState) => {
        if (notification.isOpened === 0) {
          try {
            const accessToken = await getAccessToken();
            await APIService.makeAPIPostRequest({
              requestString: "/user/notificationViewed",
              accessToken,
              body: {
                notificationId: notification.notification_id,
              },
            });

            // Update the local state and user context
            if (userState && userState.notifications) {
              const updatedNotifications = userState.notifications.map((n) =>
                n.notification_id === notification.notification_id
                  ? { ...n, isOpened: 1 }
                  : n
              );
              setUserNotifications(updatedNotifications);
            }
          } catch (error) {
            console.error("Failed to mark notification as opened", error);
          }
        }
      }, 500),
    [getAccessToken, userState, setUserNotifications]
  );

  return (
    <Box sx={{ mx: 1, display: "inline-block", position: "relative" }}>
      <Badge
        badgeContent={unopenedNotifications}
        max={9}
        color="secondary"
        onMouseEnter={handleMouseEnter}
        sx={{
          "& .MuiBadge-badge": {
            animation:
              unopenedNotifications > 0
                ? `${notificationAnimation} 0.7s ease-in-out`
                : "none",
            opacity: unopenedNotifications > 0 ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            position: "absolute",
            top: 0,
            right: 0,
          },
        }}
      >
        <NotificationsIcon color="action" />
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleMouseLeave}
        PaperProps={{
          onMouseLeave: handleMouseLeave,
          sx: {
            mt: 1,
            maxHeight: 300,
            overflow: "auto",
            width: 450,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
          },
        }}
        disableRestoreFocus
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="h6" component="h6">
            Notifications
          </Typography>
        </Box>
        <Divider />
        <List>
          {sortedNotifications.length >= 1 ? (
            sortedNotifications.map((notification, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{
                    backgroundColor:
                      notification.isOpened === 0
                        ? "white"
                        : "rgba(0, 0, 0, 0.1)", // Light gray for viewed, white for unviewed
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    position: "relative", // Required to position the "x" button
                  }}
                  onMouseEnter={() => handleNotificationHover(notification)}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveNotification(notification);
                    }}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      zIndex: 1,
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Tooltip title={notification.tooltip || ""} arrow>
                    <ListItemButton
                      sx={{ flexGrow: 1, width: "100%", pl: 2, pr: 4 }} // Adjust padding to make space for the "x" button
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <ListItemText
                        primary={notification.title}
                        secondary={
                          <>
                            {notification.text && (
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                                sx={{ display: "block", mb: 1 }}
                              >
                                {notification.text}
                              </Typography>
                            )}
                            {notification.requester_name &&
                              `From: ${notification.requester_name}`}
                          </>
                        }
                        sx={{ width: "60%" }}
                      />
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ alignSelf: "flex-end", pr: 0, pb: 1 }}
                      >
                        {notification.createdAt
                          ? `${formatDistanceToNow(
                              new Date(notification.createdAt * 1000)
                            )} ago`
                          : ""}
                      </Typography>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                {index < sortedNotifications.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2" sx={{ p: 2 }}>
              No Notifications
            </Typography>
          )}
        </List>
      </Popover>
    </Box>
  );
};
