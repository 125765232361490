import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useState } from "react";
import { json, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons/Buttons";
import { LogoutButton } from "../components/Buttons/LogoutButton";
import { UserInfoForm } from "../components/UserInfoForm";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { UserNotesDataType } from "./UserNotesView";
import EmailModal from "../components/EmailManagerModal";
import { AdminEmails, UserPreferences } from "../types/types";
import {
  Box,
  Button,
  CircularProgress,
  Switch,
  Typography,
} from "@mui/material";
import { RoundedButton } from "../styles/CustomButtons";
import { useUser } from "../context/user";
import LanguageAndNotePreferencesForm from "../components/LanguageAndNotePreferencesForm";
import APIService, { API_BASE_URL } from "../services/APIService";
import UserPreferencesForm, {
  LanguagesAndPreferences,
} from "../components/Forms/UserPreferences";
import { useUIState } from "../context/uiState";
import { clarity } from "../services/ClarityService";
import { PatientMatchModal } from "../components/Modals/PatientMatchModal";

export type UserDataType = {
  name?: string;
  institution?: string;
  email?: string;
  notes?: UserNotesDataType[];
};

interface GroupInvitation {
  group_id: string;
  group_name: string;
  invitation?: any; // You can specify more details if known
}

export const UserLoader = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const userInfo = await fetch(`${API_BASE_URL}/user/getUserInfo`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data) => {
      return data.user_info;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });
  return userInfo;
};

function UserView() {
  // const userInfo = useLoaderData() as Awaited<ReturnType<typeof UserLoader>>;
  const { getAccessToken, userState } = useUser();
  const userInfo = getUserInfoFromLocalStorage();
  const navigate = useNavigate();
  const { showAlertBanner } = useUIState();
  const { user } = useAuth0();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<AdminEmails[]>([]);
  const [languagesAndPreferences, setLanguagesAndPreferences] =
    useState<LanguagesAndPreferences>();
  const [groupInvitation, setGroupInvitation] =
    useState<GroupInvitation | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [showBillingButton, setShowBillingButton] = useState<boolean>(false);

  const [showPatientMatchModal, setShowPatientMatchModal] =
    useState<boolean>(false);

  const fetchAdminEmails = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/user/getAdminEmails",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      setEmailList(data.admin_emails);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      checkPendingInvitation(userInfo?.email);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (userState) {
      if (userState.group_user_roles) {
        if (userState.group_user_roles.includes("billing_admin")) {
          setShowBillingButton(true);
        } else {
          setShowBillingButton(false);
        }
      } else {
        setShowBillingButton(true); // user is not in any group
      }
    }
  }, [userState]);

  // check for pending group invitations
  const checkPendingInvitation = async (email: string | undefined) => {
    const accessToken = await getAccessToken();
    if (!accessToken || !email) return;

    const response = await APIService.makeAPIGetRequest({
      requestString: `/group/checkPendingInvitation?email=${encodeURIComponent(
        email
      )}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      if (data.group_name) {
        setGroupInvitation(data);
        // showAlertBanner(`You have a pending invitation from ${data.group_name}.`, "info");
      }
    }
  };

  const handleInvitationResponse = async (accept: boolean) => {
    const accessToken = await getAccessToken();
    if (!accessToken || !groupInvitation) return;

    const response = await APIService.makeAPIPostRequest({
      requestString: "/group/respondToInvitation",
      accessToken: accessToken,
      body: {
        email: userInfo?.email,
        group_id: groupInvitation.group_id,
        accept,
      },
    });

    if (response.ok) {
      setGroupInvitation(null);
      const action = accept ? "accepted" : "denied";
      showAlertBanner(`Invitation ${action} successfully.`, "success");
    } else {
      showAlertBanner("Failed to process the invitation response.", "error");
    }
  };

  // function to send verification email
  const resendVerificationEmail = async () => {
    const url = "/user/resendVerificationEmail"; // Adjust URL as necessary
    const accessToken = await getAccessToken(); // Make sure you have the token
    if (!accessToken) {
      return;
    }

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: {}, // Assuming no body is needed based on the original code
      });

      if (response.ok) {
        showAlertBanner("Verification email sent!", "success");
      } else {
        showAlertBanner("Failed to send verification email.", "error");
      }
    } catch (error) {
      showAlertBanner(
        "An error occurred while sending verification email.",
        "error"
      );
    }
  };

  const fetchLanguagesAndPreferences = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      return;
    }

    const preferencesResponse = await APIService.makeAPIGetRequest({
      requestString: `/user/getLanguagesAndPreferences`,
      accessToken: accessToken,
    });

    if (preferencesResponse.ok) {
      setLanguagesAndPreferences({
        transcriptionLanguages:
          preferencesResponse.value.transcription_languages,
        userPreferences: preferencesResponse.value.user_preferences,
      });
    }
  };

  const handleOpenEmailModal = () => {
    fetchAdminEmails();
    setEmailModalOpen(true);
  };

  useEffect(() => {
    if (user) {
      const roles = user["https://api.smartscribe.health/roles"];
      if (roles) {
        if (roles.includes("Admin") || roles.includes("Moderator")) {
          setIsAdmin(true);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    fetchLanguagesAndPreferences();
  }, []);

  // if user info hasn't been input yet, show form instead
  if (userInfo && !userInfo.name) {
    return (
      <Box>
        <UserInfoForm />
        <LogoutButton />
      </Box>
    );
  }

  return (
    <Box className="max-w-screen-lg mx-auto">
      {userInfo && (
        <Box>
          <Box
            sx={{
              border: 1,
              borderRadius: "12px",
              borderColor: "customColorMain.200",
              padding: "1rem",
              margin: "2rem",
            }}
          >
            <img
              className="w-32 mx-auto rounded-full border-8 border-white"
              src={userInfo.img}
              alt=""
            />
            <Box className="text-center mt-2 font-light text-sm">
              {userInfo.email}
              <Box
                component="span"
                sx={{
                  ml: 1,
                  py: 0.5,
                  px: 1,
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  color: userInfo.email_verified ? "green" : "red",
                  bgcolor: userInfo.email_verified ? "#e8f5e9" : "#ffebee",
                  borderRadius: "4px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: userInfo.email_verified ? "default" : "pointer",
                  "&:hover": {
                    bgcolor: userInfo.email_verified ? "#e8f5e9" : "#ffcdd2", // Lighter red on hover when not verified
                  },
                }}
                onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                  // Prevent the event from propagating if needed
                  event.stopPropagation();

                  // Call resendVerificationEmail only if email is not verified
                  if (!userInfo.email_verified) {
                    resendVerificationEmail();
                  }
                }}
              >
                {userInfo.email_verified
                  ? "Email is verified"
                  : "Email is not verified. Click to resend verification email."}
              </Box>
              {groupInvitation && (
                <Box className="max-w-screen-lg mx-auto" sx={{ p: 4 }}>
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid #666",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      You have a pending group invitation from{" "}
                      {groupInvitation.group_name}.
                    </p>
                    <p
                      style={{
                        fontSize: "0.8rem",
                        color: "#666",
                        marginTop: "10px",
                      }}
                    >
                      Note: Joining a group will allow the group administrator
                      to view your notes. Only accept this invitation if you are
                      a member of the group.
                    </p>
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, marginRight: 1 }}
                        disabled={loading}
                        onClick={() => handleInvitationResponse(true)}
                      >
                        {loading ? (
                          <CircularProgress size={"24px"} />
                        ) : (
                          "Accept"
                        )}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, marginRight: 1 }}
                        disabled={loading}
                        onClick={() => handleInvitationResponse(false)}
                      >
                        {loading ? <CircularProgress size={"24px"} /> : "Deny"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className="text-center font-normal text-lg">
              {userInfo.institution}
            </Box>
            <Box
              className="my-4 flex justify-evenly"
              sx={{
                my: 3,
                whiteSpace: "nowrap",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                alignItems: "center",
              }}
            >
              <RoundedButton
                variant="outlined"
                onClick={() => navigate("/notes")}
              >
                My Notes
              </RoundedButton>
              {userInfo.featureFlags?.emailNote && (
                <RoundedButton
                  variant="outlined"
                  onClick={() => handleOpenEmailModal()}
                >
                  Administrative Emails
                </RoundedButton>
              )}
              {isAdmin && (
                <Box>
                  <RoundedButton
                    variant="outlined"
                    onClick={() => navigate("/admin")}
                  >
                    Admin Panel
                  </RoundedButton>
                </Box>
              )}
              {showBillingButton && (
                <RoundedButton
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
                        userInfo.email || ""
                      )}`,
                      "_blank"
                    )
                  }
                >
                  Billing
                </RoundedButton>
              )}
              {userInfo.email_verified &&
                userInfo.featureFlags?.group_administration && (
                  <RoundedButton
                    variant="outlined"
                    onClick={() => navigate("/group_administration")}
                  >
                    Group Administration
                  </RoundedButton>
                )}
              <LogoutButton />
            </Box>
            <UserPreferencesForm
              onSuccessfulFormSubmission={() => {
                showAlertBanner("Preferences successfully updated!", "success");
                clarity.setTag("sessionType", "userpreferencesupdate");
              }}
              languagesAndPreferences={languagesAndPreferences}
            />
          </Box>
        </Box>
      )}
      <EmailModal
        emailList={emailList}
        emailModalOpen={emailModalOpen}
        setEmailModalOpen={setEmailModalOpen}
        fetchAdminEmails={fetchAdminEmails}
      />
      <PatientMatchModal
        isOpen={showPatientMatchModal}
        onClose={() => {
          setShowPatientMatchModal(false);
        }}
      />{" "}
    </Box>
  );
}

export default UserView;
