import {
  AdminPublicTemplate,
  AdminUserTemplate,
  AvailableModule,
  ModuleMappings,
  UserTemplateItem,
} from "../types/types";
import { RoundedButton } from "../styles/CustomButtons";
import {
  Box,
  Typography,
  Skeleton,
  IconButton,
  Divider,
  InputAdornment,
  InputBase,
  Tooltip,
  Modal,
  Snackbar,
} from "@mui/material";
import { KeyboardArrowRightOutlined, Search } from "@mui/icons-material";
import TemplateSharing from "../components/Templates/TemplateSharing";
import { DismissableCard } from "./Shared/DismissableCard";
import UploadModal from "../components/CustomTemplateUpload";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user";
import { DraggableTemplateListItem } from "../components/Templates/TemplateListItem";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  PublicTemplateLoader,
  UserTemplateLoader,
} from "../loaders/TemplatesLoader";
import TemplateEditor from "../components/Templates/TemplateEditor";
import APIService from "../services/APIService";
import NewTemplateBlock from "../components/Templates/NewTemplateBlock";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Close,
  BorderColorOutlined as BorderColorOutlinedIcon,
  DrawOutlined as DrawOutlinedIcon,
  SaveOutlined as SaveIcon,
  VisibilityOutlined as PreviewIcon,
  ContentCopyOutlined as DuplicateIcon,
  DeleteOutlined as DeleteIcon,
  SendRounded as SendIcon,
  EditOutlined as Edit,
  KeyboardArrowLeftOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { DeleteConfirmDialog } from "../components/DeleteConfirmDialog";
import LayoutWrapper from "../components/layout/UILayout";
import NotePreviewModal from "../components/Templates/PreviewTemplate";
import { useDeleteModal } from "../context/modals/DeleteModalContext";
import { removeScrollBar } from "../styles/globalStyles";
import { useUIState } from "../context/uiState";
import { useNavigate } from "react-router-dom";

const TemplatesViewRevamp = () => {
  const { templatesList, getAccessToken, refreshTemplatesList } = useUser();
  const navigate = useNavigate();
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<UserTemplateItem>();
  const [loadedTemplate, setLoadedTemplate] = useState<
    AdminUserTemplate | AdminPublicTemplate
  >();
  const [availableModules, setAvailableModules] = useState<AvailableModule[]>();
  const [publicTemplates, setPublicTemplates] =
    useState<AdminPublicTemplate[]>();
  const [addingTemplate, setAddingTemplate] = useState<boolean>(false);
  const [addingTemplateLoading, setAddingTemplateLoading] =
    useState<boolean>(false);
  const [modifiedTemplatesList, setModifiedTemplatesList] = useState<
    UserTemplateItem[] | undefined
  >(templatesList);
  const [userTemplateDeleteModalOpen, setUserTemplateDeleteModalOpen] =
    useState<boolean>(false);

  const [moduleMappings, setModuleMappings] = useState<ModuleMappings>();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [headerText, setHeaderText] = useState(
    "Welcome to the template builder!"
  );
  const [description, setDescription] = useState(
    "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
  );
  const [addContact, setAddContact] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef(0);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const copyLink =
    import.meta.env.VITE_MAIN_URL +
    `/share/templates/${selectedTemplate?.template_id}`;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const handleOpenUploadModal = () => setIsUploadModalOpen(true);
  const handleCloseUploadModal = () => setIsUploadModalOpen(false);

  const [initialTutorialCardVisible, setInitialTutorialCardVisible] =
    useState<boolean>(true);

  const StartFromScratch = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);
    setHeaderText("Build a Custom Template");
    setDescription(
      "Browse JotPsych's wide variety of sections to create your own custom template. Not seeing a section you need? "
    );
    setAddContact(true);

    const createNewUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/createUserTemplate",
      accessToken: accessToken,
      body: {
        template_name: "[Name Your Template]",
        summary_modules: [],
        sections: [],
      },
    });

    if (createNewUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: createNewUserTemplateResponse.value.template_id,
        type: "user",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const copyUserTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const copyUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/copyUserTemplate",
      accessToken: accessToken,
      body: {
        template_id: templateId,
      },
    });

    if (copyUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: copyUserTemplateResponse.value.template_id,
        type: "user",
      });
      setHeaderText("Add a new section");
      setDescription(
        "JotPsych has a wide variety of behavioral health and medical note sections in our library. Click add section to pull up the section drawer. Then, type whatever you are looking for into the search bar."
      );
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const copyPublicTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const duplicateNewUserTemplateResponse =
      await APIService.makeAPIPostRequest({
        requestString: "/template/copyPublicTemplateToUser",
        accessToken: accessToken,
        body: {
          template_id: templateId,
        },
      });

    if (duplicateNewUserTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: duplicateNewUserTemplateResponse.value.template_id,
        type: "user",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
    }
  };

  const fetchModuleMappings = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const moduleMappings = await APIService.makeAPIGetRequest({
      requestString: "/template/getModuleMappings",
      accessToken: accessToken,
    });

    if (moduleMappings.ok) {
      setModuleMappings(moduleMappings.value.module_mappings);
    }
  };

  const fetchAvailableModules = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const availableModulesResponse = await APIService.makeAPIGetRequest({
      requestString: "/template/getAvailableModules",
      accessToken: accessToken,
    });

    if (availableModulesResponse.ok) {
      setAvailableModules(availableModulesResponse.value.modules);
    }
  };

  const fetchPublicTemplates = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const publicTemplatesResponse = await APIService.makeAPIGetRequest({
      requestString: "/template/getAllPublicTemplates",
      accessToken: accessToken,
    });

    if (publicTemplatesResponse.ok) {
      setPublicTemplates(publicTemplatesResponse.value.templates);
    }
  };

  const fetchTemplate = async () => {
    setTemplateLoading(true);

    let template: AdminUserTemplate | AdminPublicTemplate;

    if (selectedTemplate?.type === "user") {
      template = await UserTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else if (selectedTemplate?.type === "public") {
      template = await PublicTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else if (selectedTemplate?.type === "group") {
      template = await UserTemplateLoader({
        params: { templateId: selectedTemplate?.template_id },
      });
    } else {
      return;
    }

    if (template) {
      setLoadedTemplate(template);
    }

    setTemplateLoading(false);
  };

  const updateUserTemplates = async (user_templates: UserTemplateItem[]) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const updateUserTemplatesResponse = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserTemplates",
      accessToken: accessToken,
      body: { user_templates },
    });

    if (updateUserTemplatesResponse.ok) {
      refreshTemplatesList();
    }
  };

  const handleSave = async (
    modifiedTemplate: AdminUserTemplate | AdminPublicTemplate
  ) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    let body = {
      template_id: modifiedTemplate.template_id,
      updated_fields: {
        template_name: modifiedTemplate.template_name,
        sections: modifiedTemplate.sections,
        summary_modules: modifiedTemplate.summary_modules,
      },
    };
    const updateUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/updateUserTemplate",
      accessToken: accessToken,
      body: body,
    });

    if (updateUserTemplateResponse.ok) {
      setLastSaved(new Date());
      setToastMessage("Template saved successfully");
      setToastOpen(true);
      if (loadedTemplate) {
        setLoadedTemplate({
          ...loadedTemplate,
          template_name: modifiedTemplate.template_name,
          sections: modifiedTemplate.sections,
          summary_modules: modifiedTemplate.summary_modules,
        });
      }
      // refreshTemplatesList();
    } else {
      setToastMessage("Failed to save template");
      setToastOpen(true);
    }
  };
  useEffect(() => {
    setModifiedTemplatesList(templatesList);
  }, [templatesList]);

  const handleTitleChange = (newTitle: string) => {
    const newTemplatesList = modifiedTemplatesList?.map((template) => {
      if (template.template_id === selectedTemplate?.template_id) {
        return { ...template, display_name: newTitle };
      }
      return template;
    });
    setModifiedTemplatesList(newTemplatesList);

    if (loadedTemplate) {
      setLoadedTemplate({ ...loadedTemplate, template_name: newTitle });
    }

    // refreshTemplatesList();
  };

  const { showDeleteModal, hideDeleteModal } = useDeleteModal();
  const {
    state: uiState,
    setState: setUIState,
    showAlertBanner,
  } = useUIState();
  const handleDelete = async () => {
    if (selectedTemplate && loadedTemplate) {
      showDeleteModal({
        isOpen: true,
        title: "Delete Template?",
        children: (
          <Typography>
            Are you sure you want to delete this template? This action cannot be
            undone.
          </Typography>
        ),
        continueText: "Delete",
        cancelText: "Cancel",
        onCancel: () => hideDeleteModal(),
        onContinue: async () => {
          const accessToken = await getAccessToken();
          if (!accessToken) return;

          if (selectedTemplate.type === "user") {
            const response = await APIService.makeAPIPostRequest({
              requestString: "/template/deleteUserTemplate",
              accessToken: accessToken,
              body: {
                template_id: loadedTemplate.template_id,
              },
            });

            if (response.ok) {
              setToastMessage("Template deleted successfully");
              setToastOpen(true);
              refreshTemplatesList();
              setSelectedTemplate(undefined);
              setLoadedTemplate(undefined);
            } else {
              setToastMessage("Failed to delete template");
              setToastOpen(true);
            }
          } else if (selectedTemplate.type === "public") {
            const response = await APIService.makeAPIPostRequest({
              requestString: "/template/removePublicTemplate",
              accessToken: accessToken,
              body: {
                template_id: loadedTemplate.template_id,
              },
            });

            if (response.ok) {
              setToastMessage("Template removed successfully");
              setToastOpen(true);
              refreshTemplatesList();
              setSelectedTemplate(undefined);
              setLoadedTemplate(undefined);
            } else {
              setToastMessage("Failed to remove template");
              setToastOpen(true);
            }
          }
          hideDeleteModal();
        },
      });
    }
  };

  const handleAddTemplate = async (publicTemplateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    setAddingTemplateLoading(true);
    setTemplateLoading(true);

    const addPublicTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/addPublicTemplateToUser",
      accessToken: accessToken,
      body: {
        public_template_id: publicTemplateId,
      },
    });

    if (addPublicTemplateResponse.ok) {
      setSelectedTemplate({
        template_id: addPublicTemplateResponse.value.template_id,
        type: "public",
      });
      refreshTemplatesList();
      setAddingTemplateLoading(false);
      setHeaderText("Now, make it yours!");
      setDescription(
        "You can make your own version of this template by clicking the 'Duplicate' button above. Then, you'll be able to add, remove, and re-order sections to create a template that's perfect for you."
      );
    }
  };

  const handleCopy = async (template?: AdminPublicTemplate) => {
    if (template) {
      await copyPublicTemplate(template.template_id);
    } else if (selectedTemplate?.type === "user") {
      await copyUserTemplate(selectedTemplate.template_id);
    } else if (selectedTemplate?.type === "public") {
      await copyPublicTemplate(selectedTemplate.template_id);
    }
  };

  const handleDuplicate = async () => {
    if (!selectedTemplate || !loadedTemplate) return;

    const accessToken = await getAccessToken();
    if (!accessToken) return;

    let newTemplateId: string;

    if (selectedTemplate.type === "user") {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/template/copyUserTemplate",
        accessToken: accessToken,
        body: {
          template_id: selectedTemplate.template_id,
        },
      });

      if (response.ok) {
        newTemplateId = response.value.template_id;
      } else {
        console.error("Failed to duplicate user template");
        return;
      }
    } else if (selectedTemplate.type === "public") {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/template/copyPublicTemplateToUser",
        accessToken: accessToken,
        body: {
          template_id: selectedTemplate.template_id,
        },
      });

      if (response.ok) {
        newTemplateId = response.value.template_id;
      } else {
        console.error("Failed to duplicate public template");
        return;
      }
    } else {
      console.error("Invalid template type for duplication");
      return;
    }

    setHeaderText("Add a new section");
    setDescription(
      "JotPsych has a wide variety of behavioral health and medical note sections in our library. Click add section to pull up the section drawer. Then, type whatever you are looking for into the search bar."
    );

    // Refresh the templates list and select the new template
    await refreshTemplatesList();
    setSelectedTemplate({
      template_id: newTemplateId,
      type: "user",
    });
  };
  const handleOpenShareModal = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleTemplateSelection = (template: UserTemplateItem) => {
    // Save the current scroll position
    if (scrollRef.current) {
      scrollPositionRef.current = scrollRef.current.scrollTop;
    }
    setUIState({
      ...uiState,
      sidebar: { isHidden: true },
    });
    setSelectedTemplate(template);
    setHeaderText("Welcome to the template builder!");
    setDescription(
      "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
    );
    setAddingTemplate(false);
    setAddContact(false);
    setLastSaved(null);
  };

  const handleOpenPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  useLayoutEffect(() => {
    // Restore the scroll position after render
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPositionRef.current;
    }
  });

  useEffect(() => {
    if (templatesList) {
      setModifiedTemplatesList(templatesList);
      if (selectedTemplate === undefined && templatesList.length > 0) {
        setSelectedTemplate(undefined);
        setHeaderText("Welcome to the template builder!");
        setDescription(
          "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
        );
        setAddContact(false);
      }
    }
  }, [templatesList]);

  useEffect(() => {
    if (selectedTemplate) {
      fetchTemplate();
    }
  }, [selectedTemplate]);

  useEffect(() => {
    refreshTemplatesList();
    fetchAvailableModules();
    fetchPublicTemplates();
    fetchModuleMappings();
  }, []);

  const onSave = (
    modifiedTemplate: AdminUserTemplate | AdminPublicTemplate
  ) => {
    if (handleSave) {
      handleSave(modifiedTemplate);
    }
  };

  const onCopy = () => {
    if (handleCopy) {
      handleCopy();
    }
  };

  const TemplateListView = () => {
    const loading = templatesList ? false : true;

    const reorder = (
      list: UserTemplateItem[],
      startIndex: number,
      endIndex: number
    ) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = async (result: any) => {
      // dropped outside the list
      if (!result.destination || !modifiedTemplatesList) {
        return;
      }

      const newTemplatesList = reorder(
        modifiedTemplatesList,
        result.source.index,
        result.destination.index
      );

      setModifiedTemplatesList(newTemplatesList);

      await updateUserTemplates(newTemplatesList);
    };

    return (
      <>
        <Box
          id="template-list"
          ref={scrollRef}
          className="flex-1"
          sx={{
            ...removeScrollBar,
            flex: 1,
            overflow: "scroll",
            flexGrow: 1,
            px: 1,
          }}
        >
          {loading && (
            <>
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
              <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
            </>
          )}
          {!loading &&
            modifiedTemplatesList &&
            modifiedTemplatesList.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {modifiedTemplatesList.map((template, index) => (
                        <Box sx={{ my: 1 }}>
                          <DraggableTemplateListItem
                            key={index}
                            index={index}
                            template={template}
                            canModify={template.type === "user" ? true : false}
                            handleClick={() =>
                              handleTemplateSelection(template)
                            }
                            isSelected={
                              selectedTemplate?.template_id ===
                              template.template_id
                            }
                          />
                        </Box>
                      ))}
                      {addingTemplateLoading && (
                        <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )}
        </Box>
      </>
    );
  };

  const TemplatePickerView = () => {
    if (!templatesList || publicTemplates === undefined) {
      return (
        <Box>
          <Skeleton />
        </Box>
      );
    }

    const excludedTemplates = templatesList?.map(
      (template) => template.template_id
    );

    return (
      <Box
        sx={{ margin: "0 auto", height: "100%", width: "95%", display: "flex" }}
      >
        <TemplateSelector
          availableTemplates={publicTemplates}
          excludedTemplates={excludedTemplates}
          onAddTemplate={handleAddTemplate}
          handleCopy={handleCopy}
          onClose={() => {
            setHeaderText("Welcome to the template builder!");
            setDescription(
              "JotPsych can organize your note contents based on your preferences. Use the template builder to add, remove, or re-order sections within a template, or create new templates. Start with a pre-built template from our library or create your own custom template from scratch."
            );
            setAddingTemplate(false);
            setAddContact(false);
          }}
          availableModules={availableModules}
        />
      </Box>
    );
  };

  const TemplateEditorView = () => (
    <>
      {templateLoading ? (
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width={"60%"} height={40} sx={{ m: 2 }} />
          <Skeleton variant="text" width={"60%"} height={20} sx={{ m: 2 }} />
          <Box
            sx={{
              width: "80%",
              margin: "0 auto",
              marginTop: 2,
            }}
          >
            <Skeleton variant="rounded" height={75} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={75} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={60} sx={{ m: 2 }} />
            <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
          </Box>
        </Box>
      ) : (
        <>
          {loadedTemplate && (
            <Box sx={{ flex: 1 }}>
              <TemplateEditor
                template={loadedTemplate}
                canModify={selectedTemplate?.type === "user" ? true : false}
                canCopyAndDelete={
                  selectedTemplate?.type === "group" ? false : true
                }
                availableModules={availableModules}
                moduleMappings={moduleMappings}
                handleTitleChange={handleTitleChange}
                handleCopy={handleCopy}
                handleSave={handleSave}
                lastSaved={lastSaved}
                onSave={onSave}
                onCopy={onCopy}
              />
            </Box>
          )}
        </>
      )}
    </>
  );

  const AddContactView = () => {
    return (
      <span>
        <a
          href={`mailto:${"support@jotpsych.com"}?subject=${encodeURIComponent(
            "SmartScribe: New Section Request"
          )}&body=${encodeURIComponent(
            `Hi! I'd like to request a new section.
              \nName:
              \nDescription of desired section:
              `
          )}`}
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Contact us
        </a>
        {" to request a new section."}
      </span>
    );
  };
  const TemplateExplanationView = () => {
    return (
      <Box
        className="header-container"
        sx={{
          backgroundColor: "backgroundColors.primary",
          borderColor: "borderColors.primary",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "1rem",
          marginBottom: "1rem",
          m: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
          }}
        >
          <DrawOutlinedIcon sx={{ mr: 1, fontSize: "h4.fontSize" }} />
          <Typography variant="h4" fontWeight="bold">
            Template Builder
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            mb: 1,
          }}
        >
          {headerText}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" sx={{ color: "textColors.secondary" }}>
            {description}
            {addContact && <AddContactView />}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <LayoutWrapper>
        <LayoutWrapper.TopBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 2,
              height: "100%",
              mx: 2,
              overflowX: "auto",
            }}
          >
            <Typography
              variant="body2"
              onClick={() =>
                setUIState({
                  ...uiState,
                  sidebar: { isHidden: !uiState.sidebar.isHidden },
                })
              }
              sx={{
                color: "primary.main",
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {uiState.sidebar.isHidden ? (
                <>
                  <MenuIcon />
                  <KeyboardArrowRightOutlined />
                </>
              ) : (
                <>
                  <MenuIcon />
                  <KeyboardArrowLeftOutlined />
                </>
              )}
            </Typography>
            {selectedTemplate && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Tooltip title="Preview">
                  <IconButton
                    onClick={handleOpenPreview}
                    sx={{ color: "primary.main" }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate">
                  <IconButton
                    onClick={handleDuplicate}
                    sx={{ color: "primary.main" }}
                  >
                    <DuplicateIcon />
                  </IconButton>
                </Tooltip>
                <RoundedButton
                  variant="outlined"
                  startIcon={<SendIcon />}
                  sx={{
                    textTransform: "none",
                    px: 2,
                    borderRadius: "0.5vh",
                  }}
                  onClick={handleOpenShareModal}
                >
                  Share
                </RoundedButton>
              </Box>
            )}
          </Box>
        </LayoutWrapper.TopBar>
        <LayoutWrapper.LeftSidebar>
          <BoxColumn flex={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "80vh",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "textColors.brand",
                  m: 2,
                  alignItems: "center",
                }}
              >
                <BorderColorOutlinedIcon sx={{ mr: 1 }} />
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="textColors.brand"
                >
                  Edit your templates
                </Typography>
              </Box>
              <TemplateListView />
              <Divider sx={{ my: 1, bgcolor: "borderColors.primary" }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "textColors.brand",
                  m: 2,
                }}
              >
                <DrawOutlinedIcon />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textColors.brand"
                  sx={{ ml: 1 }}
                >
                  Create new templates
                </Typography>
              </Box>
              {!addingTemplate && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "20vh",
                    m: 1,
                  }}
                >
                  <RoundedButton
                    variant="outlined"
                    onClick={StartFromScratch}
                    sx={{
                      flex: 1,
                      backgroundColor: "backgroundColors.secondary",
                      borderColor: "borderColors.primary",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="textColors.brand"
                      padding={1}
                    >
                      Start from scratch
                    </Typography>
                  </RoundedButton>
                  <RoundedButton
                    variant="outlined"
                    onClick={() => {
                      setHeaderText("Browse JotPsych Templates");
                      setDescription(
                        "Choose a template from JotPsych's library. You can use the template as-is or customize it to meet your needs."
                      );
                      setAddingTemplate(true);
                      setAddContact(false);
                    }}
                    sx={{
                      flex: 1,
                      backgroundColor: "backgroundColors.secondary",
                      borderColor: "borderColors.primary",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="textColors.brand"
                      padding={1}
                    >
                      Browse JotPsych library
                    </Typography>
                  </RoundedButton>
                  <RoundedButton
                    variant="outlined"
                    onClick={handleOpenUploadModal}
                    sx={{
                      flex: 1,
                      backgroundColor: "backgroundColors.secondary",
                      borderColor: "borderColors.primary",
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="textColors.brand"
                      padding={1}
                    >
                      Upload template
                    </Typography>
                  </RoundedButton>
                </Box>
              )}
            </Box>
          </BoxColumn>
        </LayoutWrapper.LeftSidebar>
        <LayoutWrapper.MainContent>
          <BoxColumn flex={3}>
            {addingTemplate ? <TemplatePickerView /> : <TemplateEditorView />}
            {!selectedTemplate && !addingTemplate && (
              <TemplateExplanationView />
            )}
          </BoxColumn>
        </LayoutWrapper.MainContent>
        <LayoutWrapper.RightSidebar>
          {(selectedTemplate || addingTemplate) && (
            <DismissableCard
              headerTitle={headerText}
              bodyText={[description]}
              actionButtonContent={addContact && <AddContactView />}
            />
          )}
          <DismissableCard
            headerTitle="Unsure where to start?"
            bodyText={[
              "Templates are one of JotPsych's most powerful tools, but we understand that they can seem a little overwhelming at first.",
              "We recommend taking a look at some of the templates in our library to get a better idea of what you can do with them.",
            ]}
            actionButtonContent={
              <RoundedButton
                variant="contained"
                onClick={() => {
                  setHeaderText("Browse JotPsych Templates");
                  setDescription(
                    "Choose a template from JotPsych's library. You can use the template as-is or customize it to meet your needs."
                  );
                  setAddingTemplate(true);
                  setAddContact(false);
                  setInitialTutorialCardVisible(false);
                }}
              >
                View JotPsych Templates
              </RoundedButton>
            }
            isVisible={initialTutorialCardVisible}
          />
        </LayoutWrapper.RightSidebar>
        {loadedTemplate && (
          <LayoutWrapper.BottomBar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
                gap: 1,
                height: "100%",
                minWidth: "max-content",
                mr: 4,
              }}
            >
              <RoundedButton
                startIcon={<DeleteIcon />}
                sx={{
                  bgcolor: "backgroundColors.primary",
                  color: "textColors.warning",
                  border: "1px solid",
                  borderColor: "borderColors.warning",
                  px: 2,
                }}
                onClick={handleDelete}
              >
                Delete template
              </RoundedButton>
              <RoundedButton
                onClick={() => loadedTemplate && handleSave(loadedTemplate)}
                startIcon={<SaveIcon />}
                sx={{
                  bgcolor: "backgroundColors.brand",
                  color: "textColors.brandContrast",
                  px: 2,
                  "&:hover": {
                    bgcolor: "backgroundColors.brand",
                  },
                }}
              >
                Save template
              </RoundedButton>
            </Box>
          </LayoutWrapper.BottomBar>
        )}
      </LayoutWrapper>
      {loadedTemplate && isPreviewOpen && (
        <NotePreviewModal
          open={isPreviewOpen}
          onClose={handleClosePreview}
          templateSections={loadedTemplate.sections}
          availableModules={availableModules}
        />
      )}
      <Modal
        open={showShareModal}
        onClose={handleCloseShareModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <TemplateSharing
            setToastOpen={setToastOpen}
            tempTitle={loadedTemplate?.template_name || ""}
            copyLink={copyLink}
            templateId={loadedTemplate?.template_id}
            setToastMessage={setToastMessage}
            setShowShareModal={setShowShareModal}
          />
        </Box>
      </Modal>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <UploadModal open={isUploadModalOpen} onClose={handleCloseUploadModal} />
    </>
  );
};

export default TemplatesViewRevamp;

interface TemplateSelectorProps {
  availableTemplates: AdminPublicTemplate[];
  excludedTemplates: string[];
  onAddTemplate: (publicTemplateId: string) => void;
  onClose: () => void;
  handleCopy?: (template: AdminPublicTemplate) => void;
  availableModules?: AvailableModule[];
}

const TemplateSelector = ({
  availableTemplates,
  excludedTemplates,
  onAddTemplate,
  onClose,
  handleCopy,
  availableModules,
}: TemplateSelectorProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredTemplates, setFilteredTemplates] = useState<
    AdminPublicTemplate[]
  >([]);

  useEffect(() => {
    // Extract unique categories
    const uniqueCategories = new Set<string>();
    availableTemplates.forEach((template) => {
      if (template.public_categories) {
        template.public_categories.forEach((category) =>
          uniqueCategories.add(category)
        );
      }
    });
    setCategories(Array.from(uniqueCategories));
  }, [availableTemplates]);

  useEffect(() => {
    // Filter templates by selected category, search term, and exclude excludedTemplates
    const filtered = availableTemplates.filter(
      (template) =>
        !excludedTemplates.includes(template.template_id) &&
        template.public_name &&
        template.public_name.length > 0 &&
        (template.public_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          template.public_description
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
    setFilteredTemplates(filtered);
  }, [availableTemplates, excludedTemplates, searchTerm]);

  return (
    <Box
      sx={{
        width: {
          xs: "90%",
          sm: "95%",
          md: "100%",
        },
        height: "75vh",
        borderRadius: "2vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: "1vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "2vh",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            SELECT A TEMPLATE
          </Typography>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "background.paper",
            borderRadius: "2vh",
            padding: "1.5vh 2vh",
            boxShadow: "0 0.5vh 1vh rgba(0,0,0,0.1)",
            marginBottom: "3vh",
          }}
        >
          <InputBase
            fullWidth
            placeholder="Search by template name or description"
            inputProps={{
              "aria-label": "search templates",
              style: { fontSize: "1.2rem", padding: "0.5vh 0" },
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flexGrow: 1 }}
          />
          <InputAdornment position="end">
            <IconButton
              type="button"
              sx={{ padding: "1vh" }}
              aria-label="search"
            >
              <Search fontSize="large" />
            </IconButton>
          </InputAdornment>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto", p: "2vh" }}>
        {filteredTemplates.map((template) => (
          <NewTemplateBlock
            key={template.template_id}
            template={template}
            handleClick={() => {
              onAddTemplate(template.template_id);
              onClose();
            }}
            handleCopy={() => {
              if (handleCopy) {
                handleCopy(template);
                onClose();
              }
            }}
            availableModules={availableModules}
          />
        ))}
        {filteredTemplates.length === 0 && (
          <Typography variant="subtitle1" align="center" color="textSecondary">
            No templates found matching search criteria.
          </Typography>
        )}
      </Box>
    </Box>
  );
};
