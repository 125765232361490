import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Box, CardActionArea } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

interface DashboardCardProps {
  children?: React.ReactNode;
  customStyles?: {
    bgcolor?: string;
    color?: string;
    padding?: string | number;
    height?: string;
  };
  navigation?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  const { children, customStyles, navigation } = props;
  const navigate = useNavigate();

  const conditionalStyles = {
    bgcolor: customStyles?.bgcolor || 'backgroundColors.secondary',
    color: customStyles?.color || 'inherit',
    padding: customStyles?.padding || 'inherit',
    height: customStyles?.height || 'inherit',
  };

  const handleNavigation = () => {
    if (navigation) {
      navigate(navigation);
    }
  };

  return (
    <Box sx={{ 
      borderRadius: 3, 
      ...conditionalStyles,
      borderColor: "borderColors.primary", 
      boxShadow: 'none', 
      border: '1px',
      cursor: navigation ? 'pointer' : 'default'
    }} onClick={navigation ? handleNavigation : undefined}>
      {children}
    </Box>
  );
};
