// globalStyles.ts

export const removeScrollBar = {
  height: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
};
  
const boxStyle = {
  display: 'flex', 
  flexDirection: 'column', 
  padding: 2, 
  color: 'textColors.brand',
  height: "200px"
}