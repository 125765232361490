import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  InputBase,
  Paper,
  Backdrop,
} from "@mui/material";
import { keyframes } from "@mui/system";
import React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  AdminPublicTemplate,
  AdminSection,
  AdminUserTemplate,
  AvailableModule,
  ModuleMappings,
} from "../../types/types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { BoxColumn } from "../layout/BoxColumn";
import NewModuleBlock from "./NewModuleBlock";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

import {
  Close,
  Search,
  BorderColorOutlined as BorderColorOutlinedIcon,
} from "@mui/icons-material";
import { DraggableSectionBlock, SectionBlock } from "./SectionBlock";
import { removeScrollBar } from "../../styles/globalStyles";

interface TemplateEditorProps {
  template: AdminUserTemplate | AdminPublicTemplate;
  canModify: boolean;
  canCopyAndDelete: boolean;
  availableModules?: AvailableModule[];
  moduleMappings?: ModuleMappings;
  handleTitleChange?: (newTitle: string) => void;
  handleSave?: (modifiedTemplate: AdminUserTemplate) => void;
  handleCopy?: () => void;
  lastSaved?: Date | null;
  onCopy: () => void;
  onSave: (modifiedTemplate: AdminUserTemplate | AdminPublicTemplate) => void;
}

const TemplateEditor = ({
  template,
  canModify,
  canCopyAndDelete,
  availableModules,
  moduleMappings,
  handleTitleChange,
  handleSave,
  handleCopy,
  lastSaved,
  onCopy,
  onSave,
}: TemplateEditorProps) => {
  const { getAccessToken } = useUser();

  const [tempTitle, setTempTitle] = useState<string>(template.template_name);
  const [titleEditMode, setTitleEditMode] = useState<boolean>(false);
  const [modifiedTemplate, setModifiedTemplate] = useState<
    AdminUserTemplate | AdminPublicTemplate
  >(template);
  const [addingModule, setAddingModule] = useState<boolean>(false);
  const [prependNewModule, setPrependNewModule] = useState<boolean>(false);
  const [modifiedModuleMappings, setModifiedModuleMappings] = useState<
    ModuleMappings | undefined
  >(moduleMappings);

  const [showRemoveMappingModal, setShowRemoveMappingModal] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [addSectionIndex, setAddSectionIndex] = useState<number | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isModuleSelectorOpen, setIsModuleSelectorOpen] = useState(false);

  const isAdminPublicTemplate = (
    template: AdminUserTemplate | AdminPublicTemplate
  ): template is AdminPublicTemplate => {
    return (template as AdminPublicTemplate).public_name !== undefined;
  };

  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Only close if the click is directly on the backdrop

    if (event.target === event.currentTarget) {
      setIsModuleSelectorOpen(false);

      setAddingModule(false);
    }
  };

  const removeModuleMapping = async (templateId: string, moduleId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const removeRequest = await APIService.makeAPIPostRequest({
      requestString: "/template/removeModuleMapping",
      accessToken: accessToken,
      body: {
        template_id: templateId,
        module_id: moduleId,
      },
    });

    if (removeRequest.ok) {
      // also remove it from local state
      setModifiedModuleMappings((prevState) => {
        const newMappings = { ...prevState };

        if (newMappings[templateId] && newMappings[templateId][moduleId]) {
          delete newMappings[templateId][moduleId];
        }

        return newMappings;
      });
    } else {
      console.error("Unable to remove module mapping", removeRequest);
    }
  };

  const handleTitleChangeEvent = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setTempTitle(event.target.value);
  };

  const handleTitleBlur = () => {
    setModifiedTemplate({
      ...modifiedTemplate,
      template_name: tempTitle,
    });
    setTitleEditMode(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // Trigger blur or end editing mode
      handleTitleBlur();
      // Optionally, you can also unfocus the input field
      (event.target as HTMLInputElement).blur();
    }
  };

  const calculateSummaryModules = (sections: AdminSection[]) => {
    let summaryModuleIds: string[] = [];
    sections.forEach((section: AdminSection) => {
      if (section.is_summary_section) {
        summaryModuleIds.push(section.module_id);
      }
    });
    return summaryModuleIds;
  };

  // a little function to help us with reordering the result
  const reorder = (
    list: AdminSection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newTemplateSections = reorder(
      modifiedTemplate.sections,
      result.source.index,
      result.destination.index
    );

    // Update the is_summary_section field so that the top three are used
    newTemplateSections.forEach((section, index) => {
      section.is_summary_section = index < 3;
    });

    setModifiedTemplate({
      ...modifiedTemplate,
      sections: newTemplateSections,
      summary_modules: calculateSummaryModules(newTemplateSections),
    });
  };
  const AddSectionCard = ({ index }: { index: number }) => (
    <Box
      onClick={() => {
        setAddSectionIndex(index);
        setAddingModule(true);
        setIsAnimating(true);
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        padding: "1.5vh 0",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderTop: "0.1vh dashed",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            top: "-1vh",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "background.paper",
            padding: "0 1vh",
            fontSize: "1.5vh",
            fontWeight: "700",
            color: "textColors.lightHeader",
          }}
        >
          ADD SECTION
        </Typography>
      </Box>
    </Box>
  );
  const LastSavedText = ({ lastSaved }: { lastSaved: Date }) => {
    if (!lastSaved) {
      return null;
    }

    return (
      <Typography
        sx={{
          marginLeft: "1rem",
          color: "primary.main",
          fontSize: { xs: 15 },
          fontWeight: 700,
          alignSelf: "end",
        }}
      >
        Last Saved:{" "}
        {lastSaved.toLocaleString("en-US", { timeZoneName: "short" })}
      </Typography>
    );
  };

  const onAddModule = (module: AvailableModule) => {
    const newSection: AdminSection = {
      module_id: module.module_id,
      display_name: module.public_name
        ? module.public_name
        : module.module_name,
      updatable: false,
      is_summary_section: true,
      model: "gpt-4",
      temperature: 0.1,
      max_tokens: 2500,
    };

    let newTemplateSections = [...modifiedTemplate.sections];

    if (addSectionIndex !== null) {
      newTemplateSections.splice(addSectionIndex, 0, newSection); // Insert at the clicked index
    } else if (prependNewModule) {
      newTemplateSections = [newSection, ...newTemplateSections];
    } else {
      newTemplateSections.push(newSection);
    }

    // Update the is_summary_section field so that the top three are used
    newTemplateSections.forEach((section, index) => {
      section.is_summary_section = index < 3;
    });

    setModifiedTemplate({
      ...modifiedTemplate,
      sections: newTemplateSections,
      summary_modules: calculateSummaryModules(newTemplateSections),
    });
  };

  const onRemoveModule = (moduleId: string) => {
    // Filter out the section with the specified moduleId
    const newTemplateSections = modifiedTemplate.sections.filter(
      (section) => section.module_id !== moduleId
    );

    // Update the is_summary_section field for the remaining sections
    newTemplateSections.forEach((section, index) => {
      section.is_summary_section = index < 3;
    });

    // Update the modifiedTemplate state
    setModifiedTemplate({
      ...modifiedTemplate,
      sections: newTemplateSections,
      summary_modules: calculateSummaryModules(newTemplateSections),
    });
  };

  useEffect(() => {
    if (modifiedTemplate != template) {
      // const updatedTemplate = {
      //   ...modifiedTemplate,
      //   template_name: tempTitle
      // };

      // setModifiedTemplate(updatedTemplate);

      // if (handleTitleChange) {
      //   handleTitleChange(tempTitle);
      // }

      // if (onSave) {
      //   onSave(updatedTemplate);
      // }

      if (
        handleTitleChange &&
        modifiedTemplate.template_name != template.template_name
      ) {
        handleTitleChange(tempTitle);
      }
      if (handleSave) {
        handleSave(modifiedTemplate);
      }
    }
  }, [modifiedTemplate]);

  const excludedModules = modifiedTemplate.sections.map(
    (section) => section.module_id
  );

  return (
    <Box
      className="flex-1"
      sx={{
        ...removeScrollBar,
        display: "flex",
        height: "100%",
        maxHeight: "75vh",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <BoxColumn flex={addingModule ? 3 : 1}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            padding: 2,
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            id="title-box"
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              ml: 2,
            }}
          >
            <Box>
              {titleEditMode ? (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    value={tempTitle}
                    onChange={handleTitleChangeEvent}
                    onBlur={handleTitleBlur}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    fullWidth
                    variant="standard"
                    InputProps={{
                      style: {
                        fontSize: "2rem",
                        fontWeight: 700,
                        fontStyle: "normal",
                      },
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  fontStyle="normal"
                  fontWeight="700"
                  sx={{ mb: 2 }}
                >
                  {modifiedTemplate.template_name}
                  {canModify && (
                    <IconButton
                      sx={{ color: "primary.main" }}
                      onClick={() => setTitleEditMode(true)}
                      size="small"
                    >
                      <BorderColorOutlinedIcon
                        sx={{
                          ml: 1,
                          color: "textColors.brand",
                          fontSize: "2rem",
                        }}
                      />
                    </IconButton>
                  )}
                </Typography>
              )}

              {isAdminPublicTemplate(template) && (
                <Box sx={{ ml: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Description
                  </Typography>
                  <Typography>{template.public_description}</Typography>
                </Box>
              )}
            </Box>
            {lastSaved && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: { xs: "stretch", md: "center" },
                  padding: { xs: "1vh", sm: "1vh 2vh" },
                  bgcolor: "backgroundColors.secondary",
                  gap: { xs: 2, sm: 0 },
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span>
                    <LastSavedText lastSaved={lastSaved} />
                  </span>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            id="scroll box"
            sx={{
              width: "95%",
              height: "100%",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Sections
            </Typography>
            {canModify ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <Box
                      sx={{ height: "60vh" }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {modifiedTemplate.sections.length > 0 ? (
                        modifiedTemplate.sections.map((section, index) => (
                          <React.Fragment key={index}>
                            <DraggableSectionBlock
                              key={index}
                              section={section}
                              index={index}
                              availableModules={availableModules}
                              handleRemove={() => {
                                onRemoveModule(section.module_id);
                              }}
                              templateModuleMap={
                                modifiedModuleMappings
                                  ? modifiedModuleMappings[
                                      modifiedTemplate.template_id
                                    ]
                                  : undefined
                              }
                              handleRevert={() => {
                                removeModuleMapping(
                                  modifiedTemplate.template_id,
                                  section.module_id
                                );
                              }}
                            />
                            {canModify && !addingModule && (
                              <AddSectionCard index={index + 1} />
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <AddSectionCard index={0} />
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Box>
                {modifiedTemplate.sections.map((section, index) => (
                  <SectionBlock
                    key={index}
                    section={section}
                    availableModules={availableModules}
                    templateModuleMap={
                      modifiedModuleMappings
                        ? modifiedModuleMappings[modifiedTemplate.template_id]
                        : undefined
                    }
                    handleRevert={() => {
                      removeModuleMapping(
                        modifiedTemplate.template_id,
                        section.module_id
                      );
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </BoxColumn>
      {/* Backdrop for blur effect */}

      {addingModule && (
        <Backdrop open={true} sx={{ zIndex: 1000 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flexGrow: 1,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(0.5px)",
            }}
            onClick={handleBackdropClick}
          />
        </Backdrop>
      )}

      {availableModules && canModify && addingModule && (
        <ModuleSelector
          availableModules={availableModules}
          excludedModules={excludedModules}
          onAddModule={onAddModule}
          onClose={() => {
            setIsAnimating(false);
            setTimeout(() => setAddingModule(false), 500);
          }}
          numSections={modifiedTemplate.sections.length}
          isOpen={isAnimating}
        />
      )}
    </Box>
  );
};

export default TemplateEditor;

interface ModuleSelectorProps {
  availableModules: AvailableModule[];
  excludedModules: string[];
  onAddModule: (module: AvailableModule) => void;
  onClose: () => void;
  numSections: number;
  isOpen?: boolean;
}

const ModuleSelector = ({
  availableModules,
  excludedModules,
  onAddModule,
  onClose,
  numSections,
  isOpen,
}: ModuleSelectorProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [filteredModules, setFilteredModules] = useState<AvailableModule[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Define the slide-in animation
  const slideIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
  `;

  // Define the slide-out animation
  const slideOut = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
  `;

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  useEffect(() => {
    // Extract unique categories
    const uniqueCategories = new Set<string>();
    availableModules.forEach((module) => {
      if (module.public_categories) {
        module.public_categories.forEach((category) =>
          uniqueCategories.add(category)
        );
      }
    });
    setCategories(Array.from(uniqueCategories));
  }, [availableModules]);

  useEffect(() => {
    const filtered = availableModules.filter(
      (module) =>
        !excludedModules.includes(module.module_id) &&
        (module.public_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          module.public_description
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          module.extraction_prompts?.semantic_prompt
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          module.extraction_prompts?.keyword_prompt
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
    setFilteredModules(filtered);
  }, [availableModules, excludedModules, searchTerm]);

  const getAnimation = () => {
    if (numSections === 0) {
      return `${fadeIn} 0.5s ease 0.3s both`;
    } else {
      return `${isOpen ? slideIn : slideOut} 0.5s ease`; // Animation for other cases
    }
  };

  const calculateHeight = () => {
    const maxHeight = "90vh";
    const minHeight = "70vh";

    if (numSections === 0) {
      return maxHeight;
    } else {
      const height = `calc(${maxHeight} - ${numSections * 80}px)`;
      return `max(${minHeight}, ${height})`;
    }
  };

  return (
    <Paper
      elevation={4}
      sx={{
        margin: { lg: "0 10vw", md: "0 10vw" },
        position: "absolute",
        bottom: 0,
        width: {
          xs: "95%",
          md: "40%",
        },
        height: {
          xs: "70%",
          md: calculateHeight(),
        },
        borderRadius: "2vh",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 0 1vh rgba(0, 0, 0, 0.1)",
        border: "0.2vh solid",
        borderColor: "divider",
        animation: getAnimation(),
        zIndex: 1001, // Ensure it's above the backdrop
      }}
    >
      <Box
        sx={{
          p: "1vh",
          padding: "2vh 5vh",
          position: "sticky",
          top: 0,
          backgroundColor: "background.paper",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "2vh",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            SELECT A SECTION
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{ position: "absolute", right: "0%", marginRight: "1em" }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "background.paper",
            borderRadius: "2vh",
            padding: "2vh 3vh",
            boxShadow: "0 0.5vh 3vh rgba(0,0,0,0.3)",
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "scale(1.02)",
              boxShadow: "0 0.8vh 4vh rgba(0,0,0,0.4)",
            },
          }}
        >
          <InputBase
            fullWidth
            placeholder="Search by section name or description"
            inputProps={{
              "aria-label": "search sections",
              style: { fontSize: "1.3rem", padding: "0.8vh 0" },
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flexGrow: 1,
              "& input::placeholder": {
                color: "text.secondary",
                opacity: 0.7,
              },
            }}
          />
          <InputAdornment position="end">
            <IconButton
              type="button"
              sx={{
                padding: "1.2vh",
                color: "primary.main",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: "primary.light",
                  transform: "rotate(90deg)",
                },
              }}
              aria-label="search"
            >
              <Search fontSize="large" />
            </IconButton>
          </InputAdornment>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, p: "2vh" }}>
        {filteredModules.map((module) => (
          <NewModuleBlock
            key={module.module_id}
            module={module}
            handleClick={() => {
              onAddModule(module);
              onClose();
            }}
          />
        ))}
        {filteredModules.length === 0 && (
          <Typography variant="subtitle1" align="center" color="textSecondary">
            No sections found matching search criteria.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};
