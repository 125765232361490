import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types";
import { formatDate } from "../../utils/utils.js";

interface UserRowProps {
  user: AdminUser;
}

export const UserRow = ({ user }: UserRowProps) => {
  const { selectUser, selectedUser } = useSelectedUser();

  const isSelected = selectedUser && user._id === selectedUser;

  return (
    <tr
      className={`hover:bg-blue-100 cursor-pointer ${
        isSelected ? "bg-blue-300" : ""
      }`}
      onClick={() => selectUser(user._id)}
    >
      <td className="px-4 py-2 border-2 border-gray-400">{user._id}</td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.user_info?.name}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {formatDate(user.last_login_timestamp!)}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">{user.logins}</td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.user_metrics?.notes_created}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.templates?.length}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {user.feature_flags?.next === true ? "true" : ""}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        <table className="w-full text-sm">
          <tbody>
            {Object.entries(user.feature_flags || {}).map(
              ([feature, enabled]) => (
                <tr key={feature}>
                  <td className="pr-2">{feature}:</td>
                  <td>{enabled ? "✅" : "❌"}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
};
