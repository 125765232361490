import { useContext, useEffect, useRef, useState } from "react";
import {
  json,
  Params,
  redirect,
  useLoaderData,
  useParams,
  useRouteError,
} from "react-router-dom";
import { NoteSection } from "../../components/NoteSection";
import { Sidebar } from "../../components/layout/Sidebar";
import { SVGIcon } from "../../components/SVGIcon";
import { MainContainer } from "../../components/layout/MainContainer";
import { formatDate, stripTags, textToParagraph } from "../../utils/utils";
import { DebugContext } from "../../main";
import { Transcript, TranscriptType } from "../../components/Transcript";
import { useAuth0 } from "@auth0/auth0-react";
import { useUIState } from "../../context/uiState";
import { DeleteModal } from "../../components/DeleteModal";
import { AdminFeedbackLoader } from "../../loaders/AdminFeedbackLoader";
import {
  AdminFeedbackEvent,
  AdminFeedbackSession,
  NoteDataType,
} from "../../types/types";
import { NoteFeedbackBox } from "../../components/NoteFeedbackBox";
import NoteFeedbackDisplayBox from "../../components/Admin/NoteFeedbackDisplayBox";
import SectionFeedbackDisplayBox from "../../components/Admin/SectionFeedbackDisplayBox";
import APIService, { API_BASE_URL } from "../../services/APIService";
import { useUser } from "../../context/user";
import { AnalysisSessionV2 } from "./AnalysisSessionInspector";
import { AnalysisDataDrawer } from "../../components/Admin/AnalysisDataDrawer";
import { Button } from "@mui/material";

interface Section {
  id: string;
}

// export const DummyNoteLoader = async ({
//   params,
// }: {
//   params: Params;
// }): Promise<NoteDataType> => {
//   const noteId = params.noteId as string;
//   const notes = dummydata["notes"] as { [key: string]: NoteDataType };
//   const notedata = notes[noteId];
//   return Promise.resolve(notedata);
// };

export const AdminNoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<NoteDataType | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const note = await fetch(`${API_BASE_URL}/notes/${params.noteId}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: NoteDataType) => {
      console.log(data);
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

function AdminNoteView() {
  const { getAccessToken } = useUser();
  const debug = useContext(DebugContext);
  const { noteId } = useParams();
  const note = useLoaderData() as Awaited<ReturnType<typeof AdminNoteLoader>>;
  const [noteData, setNoteData] = useState(note as NoteDataType);
  const [added, setAdded] = useState<number[]>([]);
  const [saving, setSaving] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<{ mode: "note" | "transcript" }>({
    mode: "note",
  });
  const { state: uiState, setState: setUIState } = useUIState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pdfGenerating, setPDFGenerating] = useState(false);
  const [feedback, setFeedback] = useState<AdminFeedbackSession>();
  const [noteFeedback, setNoteFeedback] = useState<AdminFeedbackEvent[]>();
  const [sectionFeedbackMap, setSectionFeedbackMap] = useState<{
    [sectionId: string]: AdminFeedbackEvent[];
  }>();
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  const [activeSection, setActiveSection] = useState<Section>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [analysisSession, setAnalysisSession] =
    useState<AnalysisSessionV2 | null>(null);
  const [analysisDrawerOpen, setAnalysisDrawerOpen] = useState(false);
  const [selectedAnalysisData, setSelectedAnalysisData] = useState<
    AnalysisSessionV2["node_map"][string] | null
  >(null);

  // Add this query
  const fetchAnalysisSession = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: `/notes/getAnalysisSessionFromNoteId?note_id=${noteId}`,
      accessToken,
    });
    if (response.ok) {
      setAnalysisSession(response.value.analysis_session);
    }
  };

  const fetchNoteFeedback = async () => {
    const feedbackResponse = await AdminFeedbackLoader(noteId!);
    if (feedbackResponse) {
      console.log(feedbackResponse);
      processFeedbackEvents(feedbackResponse.feedback_events);
    }
  };

  const handleAdd = (sectionIndex: number) => {
    console.log(`adding ${sectionIndex}`);
    if (added.includes(sectionIndex)) {
      setAdded((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    } else {
      setAdded([...added, sectionIndex]);
    }
  };

  const handleSectionClick = (sectionIndex: number) => {
    setActiveSection({ id: String(sectionIndex) });
  };

  const titleCase = (sentence: string) => {
    const words = sentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const newSentence = words.join(" ");

    return newSentence;
  };

  // This function separates the overall note feedback and section feedback
  const processFeedbackEvents = (feedbackEvents: AdminFeedbackEvent[]) => {
    const noteFeedback: AdminFeedbackEvent[] = [];
    const sectionFeedbackMap: { [sectionId: string]: AdminFeedbackEvent[] } =
      {};

    feedbackEvents.forEach((event) => {
      if (event.feedback_type === "note") {
        noteFeedback.push(event);
      } else if (event.feedback_type === "section" && event.section_id) {
        // If section feedback exists for the given section_id, append it, else create a new array
        sectionFeedbackMap[event.section_id] =
          sectionFeedbackMap[event.section_id] || [];
        sectionFeedbackMap[event.section_id].push(event);
      }
    });

    setNoteFeedback(noteFeedback);
    setSectionFeedbackMap(sectionFeedbackMap);
  };

  const handleRegenerateSection = async (
    moduleId: string,
    setAwaitingRegenerationTaskId: (taskId: string) => void
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/regenerateSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        module_id: moduleId,
      },
    });

    if (response.ok) {
      setAwaitingRegenerationTaskId(response.value.task_id);
    }
  };

  const handleSaveEdits = async (sectionIndex: number, editedText: string) => {
    // update data locally
    setNoteData((prevNoteData) => {
      const newNoteData = { ...prevNoteData }; // create a copy
      newNoteData.content.sections = [...newNoteData.content.sections]; // create a new reference for the sections array
      newNoteData.content.sections[sectionIndex].edited_text = editedText;
      return newNoteData;
    });

    // update data on server
    const accessToken = await getAccessToken();

    setSaving([...saving, sectionIndex]);

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/editSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_index: sectionIndex,
        edited_text: editedText,
      },
    });

    if (response.ok) {
      const data = response.value;
      setSaving((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    }
  };

  useEffect(() => {
    if (scrollRef.current && activeSection) {
      // Find the associated content section in the main container
      const contentSection = scrollRef.current.querySelector(
        `#section-${activeSection.id}`
      );

      // Scroll the main container to the content section
      contentSection?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [activeSection]);

  useEffect(() => {
    if (note?.has_feedback) {
      fetchNoteFeedback();
    } else {
      console.log("No feedback on note");
    }
  }, [note]);

  useEffect(() => {
    fetchAnalysisSession();
  }, []);

  return (
    <div>
      {noteData.content && (
        <div>
          <Sidebar
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
          >
            <div className="pt-16 flex-grow overflow-y-scroll">
              <ul>
                {noteData.content &&
                  noteData.content.sections.map((section, sectionIndex) => {
                    return (
                      <li key={sectionIndex}>
                        <div
                          onClick={() => handleSectionClick(sectionIndex)}
                          className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
                        >
                          <SVGIcon
                            name={
                              added.includes(sectionIndex)
                                ? "check-filled"
                                : "check-unfilled"
                            }
                          />
                          <span className="ml-3 capitalize">
                            {section.name}
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="pt-4 mt-4 space-y-2 border-t border-gray-200 text-center flex-shrink-0">
              <div className="w-[85%] m-auto"></div>
            </div>
          </Sidebar>
          <MainContainer scrollRef={scrollRef}>
            <div>Template Name: {noteData.template?.template_name}</div>
            <div className="mb-4 flex">
              <div className="w-12 flex-shrink-0 lg:w-20"></div>
              <div className="flex-auto mb-8">
                <div className="flex justify-between items-center">
                  <div className="text-4xl font-bold py-2">
                    {noteData.internal_title
                      ? noteData.internal_title
                      : noteData.note_id}
                  </div>
                  <div className="py-2">
                    <span className="font-bold px-2">Note</span>
                    <label className="relative inline-block w-16 h-6">
                      <input
                        type="checkbox"
                        className="peer opacity-0 w-0 h-0"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setViewMode({ mode: "transcript" });
                          } else {
                            setViewMode({ mode: "note" });
                          }
                        }}
                      />
                      <span
                        className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-blue-500 duration-300 before:content-[''] before:absolute before:w-7 before:h-4 before:bottom-1 before:left-1
                before:bg-white before:duration-300 peer-checked:before:translate-x-7 peer-checked:bg-purple-500"
                      ></span>
                    </label>
                    <span className="font-bold px-2">Transcript</span>
                  </div>
                </div>
                <div className="text-l font-bold">
                  {formatDate(noteData.time_uploaded)}
                </div>
                {noteFeedback && (
                  <NoteFeedbackDisplayBox feedbackEvents={noteFeedback} />
                )}
                {noteData.content.summary && (
                  <div>
                    {textToParagraph({ text: noteData.content.summary })}
                  </div>
                )}
              </div>
            </div>
            {viewMode.mode === "note" && (
              <div>
                {noteData.content &&
                  noteData.content.sections.map((section, sectionIndex) => {
                    const matchingNodeData = analysisSession?.node_map
                      ? Object.entries(analysisSession.node_map).find(([key]) =>
                          key.startsWith(section.module_id)
                        )?.[1]
                      : null;
                    return (
                      <div
                        key={sectionIndex}
                        id={`section-${sectionIndex}`}
                        style={{ scrollMarginTop: "4em" }}
                      >
                        <NoteSection
                          heading={section.name}
                          text={
                            section.edited_text
                              ? section.edited_text
                              : section.text
                          }
                          isRated={section.is_rated ? true : false}
                          isEdited={section.edited_text ? true : false}
                          isAdded={added.includes(sectionIndex)}
                          isErrored={section.error ? true : false}
                          handleAdd={() => handleAdd(sectionIndex)}
                          context={section.context}
                          isSaving={saving.includes(sectionIndex)}
                          forceDebug={true}
                          edits={section.edits}
                          handleRegenerateSection={handleRegenerateSection}
                          moduleId={section.module_id}
                          handleSaveEdits={(editedText) => {
                            handleSaveEdits(sectionIndex, editedText);
                          }}
                        />
                        <div className="ml-20">
                          {section.edited_text && (
                            <div>Edits: {section.edits.length}</div>
                          )}
                          {section.model && <div>Model: {section.model}</div>}
                          {section.priority !== undefined && (
                            <div>Priority: {section.priority}</div>
                          )}
                        </div>
                        <div className="ml-20 mb-20"></div>
                        {sectionFeedbackMap &&
                          sectionFeedbackMap[section.section_id] && (
                            <div className="ml-32">
                              <SectionFeedbackDisplayBox
                                feedbackEvents={
                                  sectionFeedbackMap[section.section_id]
                                }
                              />
                            </div>
                          )}
                        {matchingNodeData && (
                          <div className="ml-20 mt-4">
                            <Button
                              variant="contained"
                              onClick={() => {
                                setSelectedAnalysisData(matchingNodeData);
                                setAnalysisDrawerOpen(true);
                              }}
                            >
                              View Analysis Data
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
            {viewMode.mode === "transcript" && noteData.transcript && (
              <Transcript transcript={noteData.transcript} />
            )}
            {debug && noteData.config && (
              <pre className="text-black/40">
                {JSON.stringify(noteData.config, null, 4)}
              </pre>
            )}
          </MainContainer>
          {selectedAnalysisData && analysisSession && (
            <AnalysisDataDrawer
              open={analysisDrawerOpen}
              onClose={() => setAnalysisDrawerOpen(false)}
              analysisData={selectedAnalysisData}
              nodes={analysisSession.nodes}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AdminNoteView;
