import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  useMediaQuery,
  Theme,
  Skeleton,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import {
  ArrowBack,
  Edit as EditIcon,
  KeyboardArrowLeftOutlined,
  Save as SaveIcon,
} from "@mui/icons-material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { useNavigate, useParams } from "react-router-dom";
import PatientInformationBlock from "./PatientInformationBlock";
import TextBlock from "./TextBlock";
import StructuredDataBlock from "./StructuredDataBlock";
import PatientEncounters from "./PatientEncountersBlock";
import MedicationsDataBlock, {
  Medication,
  MedicationChange,
} from "./MedicationsDataBlock";
import DiagnosesDataBlock, { Diagnosis } from "./DiagnosesDataBlock";
import LayoutWrapper from "../layout/UILayout";
import { removeScrollBar } from "../../styles/globalStyles";
import { DismissableCard } from "../../Views/Shared/DismissableCard";

const PatientDetailView: React.FC = () => {
  const { patientId } = useParams();
  const { getAccessToken } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();

  const [patient, setPatient] = useState<Patient | null>(null);
  const [originalPatient, setOriginalPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [hasChanges, setHasChanges] = useState(false);

  const [patientModulesData, setPatientModulesData] = useState<any>(null);
  const [loadingModules, setLoadingModules] = useState(true);

  useEffect(() => {
    fetchPatient();
    fetchPatientModulesData();
  }, [patientId]);

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${patientId}&origin=PatientDetailView`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatient(data.patient);
      } else {
        throw new Error("Failed to fetch patient");
      }
    } catch (error) {
      console.error("Error fetching patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to load patient data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPatientModulesData = async () => {
    setLoadingModules(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/getPatientModulesData?patient_id=${patientId}`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatientModulesData(data.patient_modules_data);
      } else {
        throw new Error("Failed to fetch patient modules data");
      }
    } catch (error) {
      console.error("Error fetching patient modules data:", error);
      setSnackbar({
        open: true,
        message: "Failed to load patient modules data",
        severity: "error",
      });
    } finally {
      setLoadingModules(false);
    }
  };

  const handleMedicationsUpdate = async (
    updatedMedications: Medication[],
    action: "accept" | "reject" | "edit"
  ) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/updatePatientModuleData",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: "medications_block",
          data: updatedMedications,
          pending_diffs: null, // Change this from undefined to null
          action: action,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Medications updated successfully",
          severity: "success",
        });
        setPatientModulesData((prev: any) => {
          if (!prev) return null;
          return {
            ...prev,
            medications_block: {
              data: updatedMedications,
            },
          };
        });
      } else {
        throw new Error("Failed to update medications");
      }
    } catch (error) {
      console.error("Error updating medications:", error);
      setSnackbar({
        open: true,
        message: "Failed to update medications",
        severity: "error",
      });
    }
  };

  const handleDiagnosesUpdate = async (
    updatedDiagnoses: Diagnosis[],
    action: "accept" | "reject" | "edit"
  ) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/updatePatientModuleData",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: "diagnoses_block",
          data: updatedDiagnoses,
          pending_diffs: null,
          action: action,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Diagnoses updated successfully",
          severity: "success",
        });
        setPatientModulesData((prev: any) => {
          if (!prev) return null;
          return {
            ...prev,
            diagnoses_block: {
              data: updatedDiagnoses,
            },
          };
        });
      } else {
        throw new Error("Failed to update diagnoses");
      }
    } catch (error) {
      console.error("Error updating diagnoses:", error);
      setSnackbar({
        open: true,
        message: "Failed to update diagnoses",
        severity: "error",
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatient((prev) => {
      if (!prev) return null;
      const updatedPatient = { ...prev, [name]: value };
      setHasChanges(!isEqual(updatedPatient, originalPatient));
      return updatedPatient;
    });
  };

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Patient updated successfully",
          severity: "success",
        });
        setOriginalPatient(patient);
        setHasChanges(false);
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to update patient",
        severity: "error",
      });
    }
  };

  const isEqual = useCallback((obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient) {
    return <Typography>No patient data available.</Typography>;
  }

  return (
    // <Box sx={{ maxWidth: 1200, margin: "auto", p: 2 }}>
    <LayoutWrapper>
      {" "}
      <LayoutWrapper.TopBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: 2,
            height: "100%",
            mx: 2,
            overflowX: "auto",
          }}
        >
          <Typography
            variant="body2"
            onClick={() => navigate(-1)}
            sx={{
              color: "primary.main",
              textDecoration: "none",
              cursor: "pointer",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <KeyboardArrowLeftOutlined />
            Back
          </Typography>
        </Box>
      </LayoutWrapper.TopBar>
      <LayoutWrapper.MainContent>
        <Box sx={{ px: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="h4" component="h1">
                {`${patient.first_name} ${patient.last_name}`}
              </Typography>
            </Box>

            {hasChanges && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ mt: 2 }}
              >
                Save Changes
              </Button>
            )}
          </Box>

          <PatientInformationBlock
            patient={patient}
            onInputChange={handleInputChange}
          />

          {loadingModules ? (
            <Skeleton variant="rectangular" width="100%" height={200} />
          ) : (
            patientModulesData?.medications_block && (
              <MedicationsDataBlock
                medications={patientModulesData.medications_block.data}
                medicationChanges={
                  patientModulesData.medications_block.pending_diffs
                }
                onMedicationsUpdate={handleMedicationsUpdate}
              />
            )
          )}

          {loadingModules ? (
            <Skeleton variant="rectangular" width="100%" height={200} />
          ) : (
            patientModulesData?.diagnoses_block && (
              <DiagnosesDataBlock
                diagnoses={patientModulesData.diagnoses_block.data}
                diagnosisChanges={
                  patientModulesData.diagnoses_block.pending_diffs
                }
                onDiagnosesUpdate={handleDiagnosesUpdate}
              />
            )
          )}

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              "& > *": {
                flexBasis: isMobile ? "100%" : "calc(50% - 8px)",
                flexGrow: 1,
              },
            }}
          >
            {patientId && <PatientEncounters patientId={patientId} />}
          </Box>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          >
            <Alert
              onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </LayoutWrapper.MainContent>
      <LayoutWrapper.RightSidebar>
        <DismissableCard
          headerTitle="Who can see this information?"
          bodyText={[
            "JotPsych securely encrypts all identifiable patient information (name, DOB, contact info, etc.). This information is never shared with any AI models or third parties and can only be seen by you and other members of your organization after you've entered an authenticated session.",
          ]}
          learnMoreLink="https://www.jotpsych.com/security-and-privacy"
          tooltipMessage="Learn more about our security practices."
        />
        {!patientModulesData.medications_block &&
          !patientModulesData.diagnoses_block && (
            <DismissableCard
              headerTitle="Next Steps?"
              bodyText={[
                "Record an encounter with this patient (or assign a previous note to them from the Note page).",
                "After you click 'complete note', come back to this screen to review and approve changes to the patient's medications or diagnoses.",
              ]}
            />
          )}
        {(patientModulesData.medications_block ||
          patientModulesData.diagnoses_block) && (
          <DismissableCard
            headerTitle="Approve changes"
            bodyText={[
              "JotPsych automatically updates the patient's information after you complete a note. You can review and approve these changes on this page.",
              "Over time, this information will become more complete and give you a quick overview of your patients and their history.",
              "You can also manually update the patient's information, and it'll improve the accuracy of your future notes.",
            ]}
          />
        )}
      </LayoutWrapper.RightSidebar>
    </LayoutWrapper>
  );
};

export default PatientDetailView;
