import { json } from "react-router-dom";
import { AdminUser } from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const UsersLoader = async (): Promise<AdminUser[]> => {
  const accessToken = localStorage.getItem("accessToken");
  const usersList = await fetch(`${API_BASE_URL}/user/getAllUsers`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { users: AdminUser[] }) => {
      return data.users;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return usersList;
};

export interface UsersPaginationParams {
  page: number;
  pageSize: number;
  sortBy: "user_role" | "logins" | "note_count";
  sortOrder: "asc" | "desc";
  searchQuery: string;
  featureFilter: string;
}

export interface UsersResponse {
  users: AdminUser[];
  totalCount: number;
}

// Updated UsersLoader
export const PaginatedUsersLoader = async (
  params: UsersPaginationParams
): Promise<UsersResponse> => {
  const accessToken = localStorage.getItem("accessToken");
  const queryParams = new URLSearchParams({
    page: params.page.toString(),
    pageSize: params.pageSize.toString(),
    sortBy: params.sortBy,
    sortOrder: params.sortOrder,
    searchQuery: params.searchQuery,
    featureFilter: params.featureFilter,
  });

  const response = await fetch(`${API_BASE_URL}/user/getUsers?${queryParams}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  });

  if (response.status === 401) {
    throw json(
      {
        message: "You are not authorized to access this resource.",
        cta: "Login",
      },
      { status: 401 }
    );
  }

  if (!response.ok) {
    throw json(
      {
        message: "There has been an error. Please login again.",
        cta: "Login",
      },
      { status: 500 }
    );
  }

  const data: UsersResponse = await response.json();
  return data;
};
