import { Box } from "@mui/material";
import PatientsList from "../components/Patient/PatientList";
import { useUser } from "../context/user";
import LayoutWrapper from "../components/layout/UILayout";
import { DismissableCard } from "./Shared/DismissableCard";

export const PatientsView = () => {
  const { userState } = useUser();

  if (!userState?.featureFlags?.patient_index) {
    return <>You don't have access to this feature.</>;
  }

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Box sx={{ width: { xs: "100%", md: "70%" } }}>
            <PatientsList />
          </Box>
        </Box>
      </LayoutWrapper.MainContent>
      <LayoutWrapper.RightSidebar>
        <DismissableCard
          headerTitle="Who can see this information?"
          bodyText={[
            "JotPsych securely encrypts all identifiable patient information (name, DOB, contact info, etc.). This information is never shared with any AI models or third parties and can only be seen by you and other members of your organization after you've entered an authenticated session.",
          ]}
          learnMoreLink="https://www.jotpsych.com/security-and-privacy"
          tooltipMessage="Learn more about our security practices."
        />
        <DismissableCard
          headerTitle="Patient Index"
          bodyText={[
            "Here, you can view a list of all patients in your practice. Assigning patients to encounters allows JotPsych to use information from previous encounters to create more accurate notes.",
          ]}
        />
      </LayoutWrapper.RightSidebar>
    </LayoutWrapper>
  );
};
