import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import PatientPicker from "./Patient/PatientPicker";
import { Patient } from "../types/types";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { Info, InfoOutlined } from "@mui/icons-material";

interface AssignNoteToPatientProps {
  noteId: string;
  onAssignSuccess: () => void;
}

const AssignNoteToPatient: React.FC<AssignNoteToPatientProps> = ({
  noteId,
  onAssignSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
    undefined
  );
  const { getAccessToken } = useUser();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePatientSelect = (patient: Patient | undefined) => {
    setSelectedPatient(patient);
  };

  const handleAssign = async () => {
    if (!selectedPatient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/assign-note",
        accessToken,
        body: {
          patient_id: selectedPatient.patient_id,
          note_id: noteId,
        },
      });

      if (response.ok) {
        onAssignSuccess();
        handleClose();
      } else {
        console.error("Failed to assign note to patient");
      }
    } catch (error) {
      console.error("Error assigning note to patient:", error);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          onClick={handleOpen}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "primary.main",
            marginRight: 1,
          }}
        >
          Assign Note to Patient
        </Typography>
        <Tooltip title="Assign this note to a patient so JotPsych can pull forward information from this encounter next time you see this patient.">
          <InfoOutlined sx={{ color: "grey.500", fontSize: "1.2rem" }} />
        </Tooltip>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Assign Note to Patient</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, minWidth: 300 }}>
            <PatientPicker onPatientSelect={handlePatientSelect} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAssign} disabled={!selectedPatient}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignNoteToPatient;
