import React, {
  createContext,
  useContext,
  ReactNode,
  Children,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useUIState } from "../../context/uiState";
import { removeScrollBar } from "../../styles/globalStyles";
import { DismissableCard } from "../../Views/Shared/DismissableCard";
import { ViewSidebarOutlined } from "@mui/icons-material";

// Add this near the top of the file
const SIDEBAR_DOCKED_KEY = "rightSidebarDocked";

interface LayoutContextType {
  isMobile: boolean;
  hasLeftSidebar: boolean;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const childrenArray = Children.toArray(children);
  const hasLeftSidebar = childrenArray.some(
    (child) =>
      React.isValidElement(child) && child.type === LayoutWrapper.LeftSidebar
  );
  const hasTopBar = childrenArray.some(
    (child) =>
      React.isValidElement(child) && child.type === LayoutWrapper.TopBar
  );
  const hasBottomBar = childrenArray.some(
    (child) =>
      React.isValidElement(child) && child.type === LayoutWrapper.BottomBar
  );

  return (
    <LayoutContext.Provider value={{ isMobile, hasLeftSidebar }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "92vh",
          position: "relative",
        }}
      >
        {hasTopBar && (
          <>
            <TopBar>
              {
                childrenArray.find(
                  (child) =>
                    React.isValidElement(child) &&
                    child.type === LayoutWrapper.TopBar
                ) as React.ReactElement | undefined
              }
            </TopBar>
            <Divider
              sx={{ position: "absolute", top: "50px", left: 0, right: 0 }}
            />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            pb: hasBottomBar ? "60px" : 0,
            pt: hasTopBar ? "50px" : 0,
            overflow: "hidden",
          }}
        >
          {childrenArray.filter(
            (child) =>
              React.isValidElement(child) &&
              [
                LayoutWrapper.LeftSidebar,
                LayoutWrapper.MainContent,
                LayoutWrapper.RightSidebar,
              ].includes(child.type as any)
          )}
        </Box>
        {hasBottomBar && (
          <>
            <Divider
              sx={{ position: "absolute", bottom: "60px", left: 0, right: 0 }}
            />
            <BottomBar>
              {
                childrenArray.find(
                  (child) =>
                    React.isValidElement(child) &&
                    child.type === LayoutWrapper.BottomBar
                ) as React.ReactElement | undefined
              }
            </BottomBar>
          </>
        )}
      </Box>
    </LayoutContext.Provider>
  );
};

const LeftSidebar: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { state: UIState } = useUIState();
  const { isMobile } = useContext(LayoutContext)!;

  return (
    <>
      <Box
        sx={{
          ...removeScrollBar,
          width: isMobile
            ? UIState.sidebar.isHidden
              ? "0px"
              : "250px"
            : "250px",
          flexShrink: 0,
          flexGrow: 0,
          transition: "width 0.3s",
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
      <Divider orientation="vertical" flexItem />
    </>
  );
};

const MainContent: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      ...removeScrollBar,
      flexGrow: 1,
      overflow: "auto",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        maxWidth: "1500px",
        width: "100%",
        mx: "auto",
        px: 2, // Add some horizontal padding
      }}
    >
      {children}
    </Box>
  </Box>
);

const RightSidebar: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isMobile } = useContext(LayoutContext)!;
  const [hasVisibleContent, setHasVisibleContent] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isDocked, setIsDocked] = useState(() => {
    const storedValue = localStorage.getItem(SIDEBAR_DOCKED_KEY);
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkVisibleContent = () => {
      if (stackRef.current) {
        const childElements = stackRef.current.children;
        let visibleCount = 0;

        for (let i = 0; i < childElements.length; i++) {
          const child = childElements[i] as HTMLElement;
          const dataVisible = child.getAttribute("data-visible");
          if (dataVisible !== "false") {
            visibleCount++;
          }
        }

        setHasVisibleContent(visibleCount > 0);
      }
    };

    // Check content visibility after a short delay to ensure render is complete
    const timer = setTimeout(() => {
      checkVisibleContent();
      setIsInitialRender(false);
    }, 10);

    // Set up a MutationObserver to watch for changes in the sidebar's children
    const observer = new MutationObserver(checkVisibleContent);
    if (stackRef.current) {
      observer.observe(stackRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["data-visible"],
      });
    }

    return () => {
      clearTimeout(timer);
      observer.disconnect();
    };
  }, [children]);

  useEffect(() => {
    localStorage.setItem(SIDEBAR_DOCKED_KEY, JSON.stringify(isDocked));
  }, [isDocked]);

  const toggleDock = () => {
    setIsDocked((prev: boolean) => !prev);
  };

  if (isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        width: hasVisibleContent ? (isDocked ? "30px" : "300px") : "0px",
        flexShrink: 0,
        flexGrow: 0,
        transition: "width 0.3s ease-in-out",
        overflow: "hidden",
        visibility: isInitialRender ? "hidden" : "visible",
        opacity: isInitialRender ? 0 : 1,
        position: "relative",
        height: "100%",
      }}
    >
      {isDocked ? (
        <Box
          onClick={toggleDock}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderLeft: "1px solid",
            borderColor: "divider",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "action.hover",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChevronLeftIcon />
        </Box>
      ) : (
        <Tooltip title="Dock sidebar" placement="left">
          <IconButton
            onClick={toggleDock}
            sx={{
              position: "absolute",
              top: "-5px",
              right: "10px",
              zIndex: 1,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      )}
      <Box
        sx={{
          ...removeScrollBar,
          width: "300px",
          transform: isDocked ? "translateX(260px)" : "translateX(0)",
          transition: "transform 0.3s ease-in-out",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            my: 4,
            mr: 2,
            ml: isDocked ? 0 : 2,
            height: "calc(100% - 32px)",
            overflowY: "auto",
          }}
          spacing={2}
          ref={stackRef}
        >
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

const BottomBar: React.FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <Divider orientation="horizontal" flexItem />
    <Box
      className="flex-1"
      sx={{
        ...removeScrollBar,
        height: "60px",
        display: "flex",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {children}
    </Box>
  </>
);

const TopBar: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    className="flex-1"
    sx={{
      ...removeScrollBar,
      height: "50px",
      display: "flex",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
    }}
  >
    {children}
  </Box>
);

const LayoutWrapper: React.FC<{ children: ReactNode }> & {
  LeftSidebar: typeof LeftSidebar;
  MainContent: typeof MainContent;
  RightSidebar: typeof RightSidebar;
  BottomBar: typeof BottomBar;
  TopBar: typeof TopBar;
} = ({ children }) => {
  const childrenArray = Children.toArray(children);
  const hasMainContent = childrenArray.some(
    (child) =>
      React.isValidElement(child) && child.type === LayoutWrapper.MainContent
  );

  if (!hasMainContent) {
    throw new Error("LayoutWrapper must include a MainContent component");
  }

  return <Layout>{children}</Layout>;
};

LayoutWrapper.LeftSidebar = LeftSidebar;
LayoutWrapper.MainContent = MainContent;
LayoutWrapper.RightSidebar = RightSidebar;
LayoutWrapper.BottomBar = BottomBar;
LayoutWrapper.TopBar = TopBar;

export default LayoutWrapper;
